

























































































































































































































































































































































































































































































.fullWidth {
  width: 100%;
}

.font-size-14 {
  font-size: 14px;
}

.placeholder {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

#app .v-btn-toggle > .v-btn.v-btn--active {
  color: white !important;
}

#app .wrapInputInfo {
  width: 48%;
  margin: 25px 0px 25px 25px;
}

.displayTwoColumn {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: var(--v-twoColumnBackgroundColor-base);
  border: 4px solid var(--v-borderColor-base);
  border-radius: 5px;
}

.chatInfo {
  background-color: white;
  padding: 12px;
  width: 48%;
  margin: 25px;
  border-radius: 5px;
}

.topInput {
  width: 100%;
}
.topInput > * {
  margin-bottom: 10px;
}
/* .v-application .success {
  background-color: var(--v-drawerHover-base) !important;
}
.v-application .primary {
  background-color: var(--v-drawerHover-base) !important;
  border-color: var(--v-drawerHover-base) !important;
}
.v-application .primary--text {
  background-color: var(--v-drawerHover-base);
  caret-color: white;
} */
/* .v-input.v-input--is-label-active.v-input--is-dirty.v-input--is-focused.v-input--dense.theme--light.v-text-field.v-text-field--is-booted.v-text-field--enclosed.v-text-field--outlined.v-select.v-select--chips.v-select--is-menu-active.v-select--is-multi.v-autocomplete.primary--text {
  background-color: transparent !important;
}
.v-input.v-input--is-label-active.v-input--is-dirty.v-input--is-focused.v-input--dense.theme--light.v-text-field.v-text-field--is-booted.v-text-field--enclosed.v-text-field--outlined.v-select.v-select--chips.v-select--is-multi.v-autocomplete.primary--text {
background-color: transparent !important;
} */
